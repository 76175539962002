import { autoinject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { Area, Client, Location, MyHttpApi } from 'utils/api';
import { getSelectedClient } from 'utils/corporation-util';

interface UIArea extends Area {
  deleted: boolean;
}

@autoinject
export class PosAreaList {
  private locations: { [key: string]: Location; } = {};

  private fieldList: FieldSpec[] = [
    { key: "locationId", header: "app.posLocation", type: "lookup", lookupData: this.locations, lookupKey: "name", },
    { key: "name", header: "common.name", type: "text", },
    { key: "deleted", header: "common.deleted", type: "boolean", },
  ];
  private readonly defaults: { [key: string]: string; } = { "deleted": " " };
  private itemList: UIArea[] = [];
  private clientId?: number;

  constructor(private readonly api: MyHttpApi, private readonly router: Router) {
  }

  async activate() {
    let clientList = await this.api.clientList();
    let clients: { [key: number]: Client; } = {};
    for (let c of clientList) {
      clients[c.id] = { ...c, name: c.nickname };
    }
    this.clientId = getSelectedClient();
    this.fieldList = [...this.fieldList,
    { key: "clientId", header: "unit.unit", type: "lookup", lookupData: clients, lookupKey: "name", },
    ];
    let [locationList] = await Promise.all([
      this.api.locationListAll(),
      this.search(),
    ]);
    for (let location of locationList) {
      this.locations[location.id] = location;
    }
  }

  async search() {
    if (!this.clientId) {
      return;
    }
    const itemList = await this.api.areaList({ id: this.clientId, });
    this.itemList = itemList.map(x => ({...x, deleted: !!x.deleteTime}));
  }

  rowCall(key: string, row?: Area) {
    this.router.navigateToRoute(this.editUrl, { id: row?.id, clientId: this.clientId });
  }

  @computedFrom("router.currentInstruction.config.navModel.config.name")
  get editUrl() {
    let routeName = this.router?.currentInstruction?.config?.navModel?.config?.name || "";
    return routeName.replace(/-list$/, "-edit");
  }

  @computedFrom("clientId")
  get searchListener() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.search();
    return "";
  }
}
